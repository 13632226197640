.circle_image img {
  border-radius: 50%;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2), 0px -2px 4px rgba(0, 0, 0, 0.05);
  width: 150px !important;
  height: 150px !important;
  max-height: none !important;
  object-fit: cover;
}

.small_circle_image img {
  border-radius: 50%;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2), 0px -2px 4px rgba(0, 0, 0, 0.05);
  width: 4rem !important;
  height: 4rem !important;
  max-height: none !important;
  object-fit: cover;
}

.rounded_image img {
  border-radius: 20px;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2);
}

.medium_size_image img {
  max-height: 600px !important;
}

@-webkit-keyframes MOVING {
  0% {
    transform: translateX(0px) rotateZ(0deg);
  }
  25% {
    transform: translateX(5px) rotateZ(-10deg);
  }
  50% {
    transform: translateX(0px) rotateZ(0deg);
  }
  75% {
    transform: translateX(-5px) rotateZ(10deg);
  }
  100% {
    transform: translateX(0px) rotateZ(0deg);
  }
}
@-moz-keyframes MOVING {
  0% {
    transform: translateX(0px) rotateZ(0deg);
  }
  25% {
    transform: translateX(5px) rotateZ(-10deg);
  }
  50% {
    transform: translateX(0px) rotateZ(0deg);
  }
  75% {
    transform: translateX(-5px) rotateZ(10deg);
  }
  100% {
    transform: translateX(0px) rotateZ(0deg);
  }
}
@-o-keyframes MOVING {
  0% {
    transform: translateX(0px) rotateZ(0deg);
  }
  25% {
    transform: translateX(5px) rotateZ(-10deg);
  }
  50% {
    transform: translateX(0px) rotateZ(0deg);
  }
  75% {
    transform: translateX(-5px) rotateZ(10deg);
  }
  100% {
    transform: translateX(0px) rotateZ(0deg);
  }
}
@keyframes MOVING {
  0% {
    transform: translateX(0px) rotateZ(0deg);
  }
  25% {
    transform: translateX(5px) rotateZ(-10deg);
  }
  50% {
    transform: translateX(0px) rotateZ(0deg);
  }
  75% {
    transform: translateX(-5px) rotateZ(10deg);
  }
  100% {
    transform: translateX(0px) rotateZ(0deg);
  }
}
/* @-webkit-keyframes MOVING {
   0%   { opacity: 0; }
  100% { opacity: 1; }
}
@-moz-keyframes MOVING {
    0%   { opacity: 0; }
  100% { opacity: 1; }
}
@-o-keyframes MOVING {
    0%   { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes MOVING {
   0%   { opacity: 0; }
  100% { opacity: 1; }
} */

.moving {
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear !important;
  transform-origin: 50% 50%;
  -webkit-animation: MOVING 0.3s infinite; /* Safari 4+ */
  -moz-animation: MOVING 0.3s infinite; /* Fx 5+ */
  -o-animation: MOVING 0.3s infinite; /* Opera 12+ */
  animation: MOVING 0.3s infinite; /* IE 10+, Fx 29+ */
}

.max-width-1000 {
  max-width: 1000px;
}

.padding-16 {
  padding: 16px;
}

.drop-cap [data-contents]::first-letter {
  font-size: 5em;
  float: left;
  vertical-align: top;
  top: 0;
  margin-top: 0em;
  margin-right: 0.2em;
}
