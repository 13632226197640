@import url("https://fonts.googleapis.com/css?family=EB+Garamond:400,500,600,700,800|Roboto:300,400,500,700&display=swap");

body {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

img {
  max-height: 4rem !important;
}

.title-input {
  width: 100%;
  font-family: EB Garamond;
  font-style: normal;
  font-weight: 800;
  font-size: 56px;
  line-height: 72px;
  border: none;
  outline: none;
  color: #333333;
  background-color: #fafafa;
}

.title-input::placeholder {
  color: rgba(0, 0, 0, 0.24);
}

.subtitle-input {
  width: 100%;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  border: none;
  outline: none;
  color: #333333;
  margin-top: 8px;
  margin-bottom: 24px;
  background-color: #fafafa;
}

.subtitle-input::placeholder {
  color: rgba(0, 0, 0, 0.24);
}

.image-description-input {
  width: 100%;
  height: 32px;
  font-family: Roboto;
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5;
  border: none;
  outline: none;
  color: #333333;
}

.image-description-input::placeholder {
  color: rgba(0, 0, 0, 0.24);
}

.section-picker {
  width: 100%;
  height: 72px;
  /* margin-top: 56px;
  margin-bottom: 56px; */
  background-color: #fafafa;
  border-radius: 24px;
  transition: 150ms all ease-out;
}

.section-picker.draggable {
  position: absolute;
  top: 0;
  left: 0;
  /* transform: translateY(-32px); */
}

.section-picker:hover {
  background-color: #ffffff;
  box-shadow: 24px 24px 56px rgba(0, 0, 0, 0.02);
}

.section-picker:hover .container .placeholder svg {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-delay: 600ms;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-0.5px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}

.section-picker .container {
  width: 100%;
  height: 100%;
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%);
}

.section-picker .placeholder,
.section-picker .selectors {
  width: 100%;
  height: 100%;
  transition: 300ms all;
  will-change: transform;
}

/* .section-picker:hover, */
.section-picker.active {
  background-color: #ffffff;
  box-shadow: 30px 30px 80px rgba(0, 0, 0, 0.1), -30px -30px 80px #ffffff;
}

.section-picker.active .placeholder,
.section-picker.active .selectors {
  transform: translateY(-100%);
}

.section-picker .placeholder {
  cursor: pointer;
  text-align: center;
}

.section-picker .placeholder p {
  margin: 0px;
  font-size: 14px;
  font-weight: 500;
  line-height: 72px;
  color: #003a78;
}

.section-picker .placeholder p svg {
  vertical-align: middle;
  margin-bottom: 4px;
  margin-left: 16px;
  color: #003a78;
}

.section-picker .selectors .selector {
  height: 100%;
  display: inline-block;
  text-align: center;
  padding: 8px;
  box-sizing: border-box;
  position: relative;
}

.section-picker .selectors .selector div {
  height: 100%;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0);
  transition: 150ms all;
  cursor: pointer;
}

.section-picker .selectors .selector div:hover {
  background-color: rgba(0, 58, 120, 0.12);
}

.section-picker .selectors .selector div:active {
  background-color: rgba(0, 58, 120, 0.24);
}

.section-picker .selectors .selector p {
  font-size: 14px;
  font-weight: 500;
  line-height: 58px;
  color: #003a78;
  margin: 0px;
}

.section-picker .selectors .selector p svg {
  vertical-align: middle;
  margin-bottom: 4px;
  margin-right: 16px;
  color: #003a78;
}

/* Draggable */

.create-page > div,
.edit-page > div {
  background-color: #fafafa;
  box-shadow: none;
  overflow: visible;
}

.simple-form {
  background-color: white;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding-top: 96px;
  padding-bottom: 72px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.simple-form.no-card {
  background-color: #fafafa;
  box-shadow: none;
  border-radius: none;
}

.simple-form > div:nth-child(2) {
  background-color: white;
  border-radius: 4px;
  padding: 0px;
  align-items: stretch;
}

.simple-form.no-card > div:nth-child(2) {
  background-color: #fafafa;
}

.simple-form > div:nth-child(2) > div {
  height: 100%;
  margin-left: 24px;
  margin-right: 24px;
  align-items: stretch;
}

.simple-form > div:nth-child(2) > div > button {
  width: calc(50% - 24px);
  height: 64px;
  box-shadow: none;
}

div[data-rbd-droppable-id] {
  width: 100%;
}

.draggable-container {
  width: 100%;
  /* padding-top: 72px; */
  /* padding-bottom: 72px; */
  padding-top: 104px;
  padding-bottom: 36px;
}

.draggable-content {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  transform: scale(1);
  transition: 150ms;
  will-change: transform;
}

.draggable-bar {
  height: 48px;
  background-color: #ffffff;
  border-radius: 12px;
}


.draggable-bar .draggable-bar-left svg {
  vertical-align: middle;
  margin-bottom: 4px;
  margin-right: 16px;
}

.draggable-bar .draggable-bar-delete {
  margin-right: 8px;
}

.draggable-bar .draggable-bar-delete span {
  color: rgba(0, 0, 0, 0.54);
}

.rte {
  border: none !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.rte-toolbar {
  height: 42px;
  background-color: rgba(0, 0, 0, 0.04);
  margin: 0 !important;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom: 0px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding: 6px 24px !important;
  box-sizing: border-box;
}

.rte-toolbar div[class*="ButtonWrap"] {
  margin-right: 4px;
}

.rte-toolbar button {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  background: none;
  border-radius: 4px !important;
  transition: 100ms;
}

.rte-toolbar button:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.rte-toolbar button[class*="isActive"],
.rte-toolbar button:active {
  background-color: #003a78;
  outline: none;
}

.rte-toolbar button[class*="isActive"] span,
.rte-toolbar button:active span {
  filter: invert();
  outline: none;
}

.rte-toolbar button:disabled {
  background-color: rgba(0, 0, 0, 0) !important;
  /* background: rgba(0, 0, 0, 0)!important; */
}

.rte-toolbar button:disabled span {
  opacity: 0.2;
  filter: none;
}

.rte-editor {
  font-family: EB Garamond;
  font-size: 20px !important;
  font-weight: 500;
  padding: 24px;
}

/* Image selector */


.image-selector .image-selector-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 58, 120, 0.04);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  cursor: pointer;
  transition: 150ms;
}

.image-selector .image-selector-container:hover,
.image-selector .image-selector-container.active {
  background-color: rgba(0, 58, 120, 0.12);
}

.image-selector .image-selector-container .content {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-selector .image-selector-container .content p {
  color: #003a78;
  font-weight: 500;
}


.image-container .description {
  padding: 24px;
  box-sizing: border-box;
  display: flex;
}

.description {
  padding: 24px;
}

.image-container .description > span {
  margin-right: 36px;
  flex-grow: 1;
}

.images-container {
  width: 100%;
  padding-top: 24px;
  padding-left: 24px;
  box-sizing: border-box;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #ffffff;
}

.images-container .image {
  display: inline-block;
  width: calc((100% - (24px * 3)) / 3);
  padding-bottom: 24px;
  padding-right: 24px;
}

.images-container .image .container {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  outline: none;
  box-shadow: 0px 0px 0px 0px #003a78;
  transition: 150ms all;
  border-radius: 5px;
}

.images-container .image.selected .container {
  box-shadow: 0px 0px 0px 2px #003a78;
}

.images-container .image .container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh !important;
  border-radius: 4px;
  object-fit: cover;
  z-index: 18;
}

.images-container .image .container .image-delete-button {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 20;
  color: #ffffff;
  opacity: 0;
  transition: 150ms all;
}

.images-container .image .container .image-previous-button {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 20;
  color: #ffffff;
  opacity: 0;
  transition: 150ms all;
}

.images-container .image .container .image-next-button {
  position: absolute;
  top: 8px;
  left: 56px;
  z-index: 20;
  color: #ffffff;
  opacity: 0;
  transition: 150ms all;
}

.images-container .image .container:hover .image-delete-button,
.images-container .image .container:hover .image-previous-button,
.images-container .image .container:hover .image-next-button {
  opacity: 1;
}

.images-container .image .container:hover .image-delete-button:disabled,
.images-container .image .container:hover .image-previous-button:disabled,
.images-container .image .container:hover .image-next-button:disabled {
  color: rgba(255, 255, 255, 0.3);
}

.images-container .image .container .upload {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px dashed #003a78;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  outline: none;
  transition: 150ms;
}

.images-container .image .container .upload:hover,
.images-container .image .container .upload.active {
  background-color: rgba(0, 58, 120, 0.12);
}

.images-container .image .container .upload button,
.images-container .image .container .upload > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.test {
  width: 100%;
  height: 120px;
  background: linear-gradient(45deg, red, blue);
}

.is-dragging .draggable-content {
  border: 2px solid #003a78;
  transform: scale(0.9);
}

.hidden-input {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  /* opacity: 1; */
  cursor: pointer;
  opacity: 0;
}

span[class^="ImageSpan__"] {
  max-width: 300px !important;
  max-height: 300px !important;
  background-size: cover !important;
  line-height: 300px !important;
  float: right;
  border-radius: 24px;
  margin-left: 16px;
  margin-bottom: 8px;
}

.upload-image-button {
  position: absolute !important;
  top: 50px;
  right: 16px;
}

